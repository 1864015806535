* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 150%;
  font-family: 'Montserrat', sans-serif;
  /* background: #1876c4; */
  // Remove Highlight
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

iframe {
  border: none;
}

button {
  outline: none;
  border: none;
}

a {
  color: unset;
  text-decoration: unset;
}

.wrapper {
  line-height: 0;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

// Animated Hamburger

$bar-width: 20px;
$bar-height: $bar-width/10;
$bar-spacing: $bar-width/3;
$bar-color: #000;

.menu-wrapper {
  margin: auto;
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
  @media (min-width: 50em) {
    display: none;
  }
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: $bar-color;
  transition: all 0ms 300ms;
  width: 17px;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: $bar-color;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: $bar-color;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
